import React, { useState, useEffect } from 'react';
import './InfoModal.css';
import Modal from 'react-bootstrap/Modal';
import TaxDocumentsModal from './TaxDocumentModal';

const InfoModal = ({ sentCount, flip }) => {
  const [modalTitle, setModalTitle] = useState('');
  const [show, setShow] = useState(false);
  const [modalDescription, setModalDescription] = useState('');

  const modalTitleList = {
    0: 'Our Office',
    1: 'Email',
    2: 'Contact us',
    3: 'Instant refund',
    4: 'What to bring'
  };

  const modalDescriptionList = {
    0: `<p><strong>Serving the Durham Region for over 15 years</strong>, Liberty Tax Ajax is known for its <em>friendly and effective tax services</em>.</p>
<p>Our experienced team is dedicated to making your tax preparation process as <strong>smooth and stress-free</strong> as possible. Whether you need help with personal or business taxes, we are here to provide <strong>reliable, accurate, and efficient service</strong>.</p>
<p><strong>Trust Liberty Tax Ajax</strong> to handle your taxes with <em>care and expertise</em>, ensuring you get the best possible outcome.</p>
`,
    1: `<p>If you wish to complete your return online, or need to submit any documents to our office, please send all documents and/or messages to:</p>
        <p><strong>libertytaxajax@bellnet.ca</strong></p>`,
    2: `<p><strong>Address:</strong><br>
        110 Ritchie Ave, Ajax ON, L1S 7G5</p>
        <p><strong>Phone Number:</strong><br>
        905-619-3619</p>
        <p><strong>Summer Hours:</strong><br>
        Monday, Wednesday, Friday: 9am-5pm<br>
        Tuesday, Thursday: 12pm-8pm</p>`,
    3: `<p>Our office offers instant refunds to qualifying clients.</p>
        <p>The fees paid for an Instant Refund are regulated by the Federal Government of Canada as follows:</p>
        <ul>
          <li><b>15% </b> on the first <b>$300 </b>of your refund</li>
          <li><b>5%</b> on the rest</li>
        
        </ul>
        <br/>
        <p>In order to qualify you must: </p>
        <ul>
        <li>Have <b>no outstanding debt</b> with the CRA</li>
        <li>Be completely <b>up to date on all previous filing years</b></li>
        <li>Provide <b>two non-family references </b></li>
        <li>Have a refund that is <b>no less than $500</b> and <b>no greater than $5000</b></li>
        </ul>
        <p>Please contact our office for more information.</p>
        `,
        
    4: <TaxDocumentsModal />
  };

  useEffect(() => {
    setModalTitle(modalTitleList[sentCount]);
    setModalDescription(modalDescriptionList[sentCount]);
    setShow(true);
  }, [flip, sentCount]);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {typeof modalDescription === 'string' ? (
            <div dangerouslySetInnerHTML={{ __html: modalDescription }} />
          ) : (
            modalDescription
          )}
        </Modal.Body>
        <Modal.Footer>
          {/* You can add buttons here if needed */}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default InfoModal;