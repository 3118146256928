import React, { useState } from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import './TaxDocumentModal.css';
const TaxDocumentsModal = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const taxDocuments = [
    { category: "Income Documents", items: [
      "T4 slips (employment income)",
      "T4A slips (other income, including pensions, annuities, etc.)",
      "T5 slips (investment income)",
      "T3 slips (trust income)",
      "T5007 (social assistance payments)",
      "T4E (Employment Insurance benefits)",
      "T4RSP, T4RIF (income from RRSP or RIFF)",
    ]},
    { category: "Deduction and Credit Documents", items: [
      "RRSP contribution receipts",
      "Charitable donation receipts",
      "Political contribution receipts",
      "Child care expense receipts",
      "T2202A (tuition and education amounts)",
      "Medical expense receipts",
      "T2200 (Declaration of Conditions of Employment)",
      "Property tax bills or rent receipts",
    ]},
    { category: "Self-Employment Documents", items: [
      "Income records (invoices, sales receipts)",
      "Expense receipts",
      "Vehicle logbook and expenses (if applicable)",
      "Home office expenses (if applicable)",
    ]},
    { category: "Investment and Property Documents", items: [
      "T5008 (Statement of Securities Transactions)",
      "Rental income and expense records",
      "Records of sale of stocks, bonds, or real estate",
    ]},
    { category: "Other Potential Documents", items: [
      "Notice of Assessment from previous year",
      "Any correspondence from CRA",
      "Social Insurance Number for you and dependents",
      "Spousal information (if applicable)",
      "Direct deposit information",
    ]},
  ];

  return (
    <>

          <p>Here's a list of documents you might need to file your taxes in Ontario. Please note that this list is not exhaustive and your specific situation may require additional documents.</p>
          {taxDocuments.map((category, index) => (
            <div key={index}>
              <h5>{category.category}</h5>
              <ListGroup variant="flush">
                {category.items.map((item, itemIndex) => (
                  <ListGroup.Item key={itemIndex}>{item}</ListGroup.Item>
                ))}
              </ListGroup>
              <br />
            </div>
          ))}

    </>
  );
};

export default TaxDocumentsModal;