import React from 'react'
import Background from '../components/Background'
import Main from '../components/Main'

const Home = () => {
  return (
    <div>
        <Background/>
        <Main/>
    </div>
  )
}

export default Home