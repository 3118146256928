import React, {useState, useEffect} from 'react';
import './Main.css';
import MainNav from './MainNav';
import AccordionComponent from './AccordionComponent';
import libImage from './libtaxnew.png';
import libLarge from './libtaxbig.png';
import 'animate.css';
import MainContent from './MainContent';
const Main = () => {


  return (
    <div className="parentContainer">
      <div className="mainContainer">
      <div className="mainDiv">
        
        <div className="navParent animate__animated animate__fadeIn">
        <div className="logoDiv">
            <img src={libLarge} alt="logoImage" className="logoImage"/>

          </div>
          <div className="navDiv">
            <MainNav />
          </div>        
        </div>
        <div className="mainContent">
        <MainContent/>
      </div>
      </div>
      <div>
      </div>
        

      </div>
    </div>
  );
};

export default Main;
