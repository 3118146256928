import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import './AppointmentModal.css'
import appPic from './appointmentpic.png'

const AppointmentModal = () => {
  const [show, setShow] = useState(false)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768)

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleShow = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <div className="appointmentCDiv">
      <button onClick={handleShow} className="appointmentButtonMain">
        <span>
          <span className="buttonContainer">
            <span className="buttonPrimary"></span>
            <span className="buttonComplimentary"></span>
          </span>
        </span>
        <span className="buttonText" style={isMobile ? {fontSize: "0.75em"} : {fontSize: "1.25em"}}>
          Book Now
        </span>
      </button>

      <Modal show={show} onHide={handleClose} className="appointment-modal">
      <Modal.Header closeButton>
        <Modal.Title>Book Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body className="position-relative">
        <div className="demo-overlay">
          <span>Demo Purposes Only</span>
        </div>
        <iframe
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3Jp8ns_q8vcxcbgQZxrlmg-SmjSTrwYsxak4KCHvWARtXb254B4Eza5TpV_dYYRKMxstfCVt70?gv=true"
          width="100%"
          height="600"
          frameBorder="0"
        ></iframe>
      </Modal.Body>
      <Modal.Footer>
        {/* You can add footer content here if needed */}
      </Modal.Footer>
    </Modal>
    </div>
  )
}

export default AppointmentModal