import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import './MobileNav.css'
import InfoModal from './InfoModal';
import navIcon from './navBarIcon.png';

const MobileNav = () => {
  const expand = 'lg';
  const [count, setCount] = useState(0);
  const [flip, setFlip] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleModal = (counter) => {
    setFlip(!flip);
    setCount(counter);
    setShowModal(true);
  };

  return (
    <div>
      <Navbar expand={expand} className="mobileNav">
        <Container>
         
          <Navbar.Toggle aria-controls="lg" />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${expand}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                Liberty Tax Ajax
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link  onClick={() => handleModal(0)}>Our office</Nav.Link>
                <Nav.Link onClick={() => handleModal(4)}>What to bring</Nav.Link>
                <Nav.Link  onClick={() => handleModal(1)}>Email</Nav.Link>
                <Nav.Link  onClick={() => handleModal(3)}>Instant refund</Nav.Link>
                <Nav.Link onClick={() => handleModal(2)}>Contact us</Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      {showModal &&(
        <InfoModal sentCount={count} flip={flip} />
      )}
    </div>
  );
};

export default MobileNav;