import React, { useState, useEffect } from 'react';
import './MainContent.css';
import { TypeAnimation } from 'react-type-animation';
import TaxDocumentsModal from './TaxDocumentModal';
import AppointmentModal from './AppointmentModal';

const MainContent = () => {
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [mainFontSize, setMainFontSize] = useState('4em');
  const [isLoading, setIsLoading] = useState(true);
  const [bookText, setBookText] = useState(1)
  const handleDocumentModal = () => {
    setShowDocumentModal(true);
  };


  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setMainFontSize(mobile ? '2em' : '4em');
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    // Set loading to false after initial resize
    setIsLoading(false);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (isLoading) {
    return <div>Loading...</div>; // Or any loading indicator you prefer
  }

  return (
    <div className="contentContainer">
      <div className="contentMain">
        <div className="textAnimateDiv">
          <TypeAnimation
            sequence={[
              'Quick',
              1000,
              'Easy',
              1000,
              'Effective',
              1000,
              'Liberty Tax Ajax',
              4000
            ]}
            wrapper="span"
            speed={50}
            style={{ fontSize: mainFontSize, display: 'inline-block' }}
            repeat={0}
          />
        </div>
        <div className="appointmentDiv" style={isMobile ? {width:"50%"} :{}}>
          <AppointmentModal mobile = {isMobile}/>
        </div>
        {/* <div className="documentDiv">
          <TaxDocumentsModal />
        </div> */}
        <div className="mapDiv" >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11508.257725325622!2d-79.0408153!3d43.8544932!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4df1aa4296905%3A0x6532446a66e27bb8!2sLiberty%20Tax!5e0!3m2!1sen!2sca!4v1689525769791!5m2!1sen!2sca"
            style={{
              border: "0",
              borderRadius: "25px",
              width: "100%",
              height: "40vh"
            }}
            allowFullScreen="no"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Liberty Tax Location"
          />
        </div>
      </div>
    </div>
  );
};

export default MainContent;