import React from 'react';
import { motion } from 'framer-motion';
import './Background.css';

const Background = () => {
  return (
    <motion.div
      className="backgroundDiv"
      initial={{ background: "linear-gradient(#FF7F7F, #FFFFFF)" }}
      animate={{ background: ["linear-gradient(#FF7F7F, #FFFFFF)", "linear-gradient(#FFFFFF, #FF7F7F)"] }}
      transition={{ duration: 5, yoyo: Infinity }}
    />
  );
}

export default Background;