import React, { useState, useEffect } from 'react';
import './MainNav.css';
import InfoModal from './InfoModal';
import MobileNav from './MobileNav';
import navIcon from './navBarIcon.png';
const MainNav = () => {
  const [showModal, setShowModal] = useState(false);
  const [count, setCount] = useState(null);
  const [flip, setFlip] = useState(false);
  const [isMobile, setIsMobile] = useState(false)
  const [showVert, setShowVert] = useState(false)

  const handleModal = (counter) => {
    setFlip(!flip);
    setCount(counter);
    setShowModal(true);
  };
  useEffect(()=>{
    const handleResize = () =>{
      setIsMobile(window.innerWidth <= 768);


    };
    handleResize()
    window.addEventListener('resize', handleResize);
    return () =>{
      window.removeEventListener('resize', handleResize)
    };
    

  },[]);
  const handleVert = ()=>{
    setShowVert(!showVert)
  }
  const navBar = () =>{
    return (
      <div>

<ul className={showVert ? "vertNav" : "navItems"} style={showVert ? {paddingTop: "50px"} : {}}> <a onClick={() => handleModal(0)}>
            <li>Our office</li>
          </a>
          <a onClick={() => handleModal(1)}>
            <li>Email</li>
          </a>
          <a onClick={() => handleModal(4)}>
            <li>Documents</li>
          </a>
          <a onClick={() => handleModal(3)}>
            <li>Instant refund</li>
          </a>
          <a onClick={() => handleModal(2)}>
            <li>Contact us</li>
          </a>
        </ul>
        </div>
    )
  }
  useEffect(()=>{
    if (!isMobile){
      setShowVert(false)
    }
  },[isMobile])



  return (
    <div className="containerContain">
      <div className="navContainer">
      {isMobile ? (
        <div className="conditionalContainer">
        <div className="navIconContainer">
          <a onClick={handleVert}>
            
              <MobileNav/>
            
        </a>
       
        

        </div>
      
        </div>
      ) : (
        navBar()
      )}
    </div>
      {showModal && <InfoModal sentCount={count} flip={flip}/>}
    </div>
  );
};

export default MainNav;
